<template>
  <div>
    <div class="toolbar">
      <div class="h-input-group">
        <input type="text" placeholder="请输入店铺名称或编码" v-model="filterVal"/>
        <Button color="primary" icon="fa fa-search" @click="doSearch">查询</Button>
      </div>
    </div>
    <Table :datas="pageData.results" :loading="isLoading">
      <TableItem title="编码">
        <div @click="view(data)" class="text-hover fa fa-eye" slot-scope="{data}">
          {{ data.code }}
        </div>
      </TableItem>
      <TableItem title="类别" prop="category"/>
      <TableItem title="名称" prop="merchantName"/>
      <TableItem title="地址" prop="address"/>
      <TableItem title="联系人" prop="contacts"/>
      <TableItem title="电话" prop="contactNumber"/>
      <TableItem title="到期时间" prop="dueDate"/>
      <TableItem title="支付功能">
        <div @click="purchase(data)" class="text-hover fa fa-eye" slot-scope="{data}">
          {{ status[data.openPay] }}
        </div>
      </TableItem>
      <TableItem title="状态">
        <span slot-scope="{data}">
          {{ data.status == 1 ? '已通过' : '待审核' }}
        </span>
      </TableItem>
      <TableItem title="资源下载">
        <a slot-scope="{data}" target="_blank" :href="'/api/merchant/qrcode?merchantId='+data.id">
          会员注册台签
        </a>
      </TableItem>
      <TableItem>
         <span slot-scope="{data}" class="text-hover h-icon-upload" @click="importMember(data)">
           导入会员
         </span>
      </TableItem>
    </Table>
  </div>
</template>

<script>
import Merchant from "@/api/Merchant";

export default {
  name: "Merchant",
  data() {
    return {
      pageData: {},
      filterVal: '',
      isLoading: false,
      status: ['待申请', '待审核', '审核失败', '审核通过']
    }
  },
  methods: {
    loadList(params) {
      Merchant.list(params).then(({data}) => {
        this.pageData = data;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    doSearch() {
      this.loadList({filterVal: this.filterVal})
    },
    importMember(entity) {
      this.$Modal({
        title: '导入会员信息',
        closeOnMask: false,
        esc: true,
        component: {
          vue: (resolve) => {
            require(['./merchant/ImportMember'], resolve);
          },
          datas: {
            id: entity.id
          }
        },
        events: {
          close: () => {
          }
        }
      });
    },
    view(entity) {
      this.$Modal({
        title: '商户信息',
        type: 'drawer-right',
        component: {
          vue: (resolve) => {
            require(['./merchant/MerchantView'], resolve);
          },
          datas: {
            entity
          }
        },
        events: {
          close: () => {
            this.loadList();
          }
        }
      });
    },
    purchase(entity) {
      this.$Modal({
        title: '直购信息审核',
        type: 'drawer-right',
        component: {
          vue: (resolve) => {
            require(['./merchant/MerchantPurchase'], resolve);
          },
          datas: {
            entity
          }
        },
        events: {
          close: () => {
            this.loadList();
          }
        }
      });
    },
    toForm() {
      this.$Modal({
        title: '商户信息',
        closeOnMask: false,
        middle: true,
        component: {
          vue: (resolve) => {
            require(['./merchant/MerchantForm'], resolve);
          }
        },
        events: {
          success: (modal) => {
            this.loadList();
          }
        }
      });
    }
  },
  created() {
    this.loadList();
  }
}
</script>
