/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2019 soho team All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2019年11月19日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
  list(params = {}) {
    return Ajax.get('/merchant', params);
  },
  save(params = {}) {
    if (params.id) {
      return Ajax.put('/merchant', params);
    }
    return Ajax.post('/merchant', params);
  },
  remove(id) {
    return Ajax.delete(`/merchant/${id}`);
  },
  audit(params = {}) {
    return Ajax.post('/merchant/register/audit', params);
  },
  auditPay(params = {}) {
    return Ajax.post(`/merchant/audit/pay`, params);
  },
  import(params = {}) {
    return Ajax.post('/merchant/import', params)
  },
  load(id) {
    return Ajax.get(`/merchant/${id}`);
  },
  auditInfo(params = {}) {
    return Ajax.post('/merchant/info/audit', params);
  },
  orderInfo(params = {}) {
    return Ajax.get('/merchant/order/info', params);
  },
  orderAudit(params = {}) {
    return Ajax.post('/merchant/order/audit', params);
  }
}
